import ReportTable from "components/Report";
import dayjs from "dayjs";
import customDateFormat from "dayjs/plugin/customParseFormat";
import ls from "Localization";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Container, Row, Tooltip } from "reactstrap";
import {
	ObligationPeriods,
	ObligationStatusDASN
} from "types/obligationStatus";
import * as actions from "./actions";
import { AiOutlineReload } from "react-icons/all";

dayjs.extend(customDateFormat);

let getObligationsDebounced = null;

function ObligationsInCompany({ history, companyId, cnpj }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { items, pages, loading } = useSelector(state => state.obligationList);
	const { item: company } = useSelector(state => state.companyDetail);

	const obligationColors = ["orange", "green", "red", "info", "#289bbf"];

	const [tooltipReloadDasnOpen, setTooltipReloadDasnOpen] = useState(false);
	const toggleReloadDasn = () =>
		setTooltipReloadDasnOpen(!tooltipReloadDasnOpen);

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getObligationsDebounced) {
			getObligationsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate && creationDate.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getObligationsDebounced = _.debounce(
			() =>
				dispatch(
					actions.getObligations(
						page * pageSize,
						pageSize,
						`&filters[business]=${companyId}` +
							filtered.reduce((p, c) => {
								return p + `&filters[${c.id}]=${c.value}`;
							}, ""),
						sorted[0] ? sorted[0].id : undefined,
						sorted[0] ? sorted[0].desc : undefined,
						toExport,
						callback
					)
				),
			500
		);
		getObligationsDebounced();
	};

	// const load = () =>
	// 	handleFetchData(table.current ? table.current.state : undefined);

	const handleReloadDasn = () => {
		alert(`As obrigações podem demorar alguns minutos para serem processadas.`);

		dispatch(
			actions.reloadTaxes(cnpj, err => {
				if (err) {
					console.log(String(err));
				}
			})
		);
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col md={12}>
					<ReportTable
						manual
						tableRef={table}
						title={"Obrigações"}
						data={items}
						pageSize={5}
						pages={pages}
						loading={loading.getAll}
						filterable
						headerRightComponent={
							<div>
								<span className={"mr-2"}>
									Atualizado em:{" "}
									{company?.lastUpdatedDasn
										? dayjs(company?.lastUpdatedDasn).format(
												ls.dateFormatWithoutSeconds
										  )
										: items.length && items[0].updatedAt
										? dayjs(items[0].updatedAt).format(
												ls.dateFormatWithoutSeconds
										  )
										: ""}
								</span>
								<Button
									className={"mr-2"}
									outline
									onClick={handleReloadDasn}
									id="tooltipReloadDasn"
									style={{ marginLeft: 5 }}
								>
									<AiOutlineReload style={{ margin: 0 }} />
								</Button>
								<Tooltip
									placement="top"
									isOpen={tooltipReloadDasnOpen}
									target="tooltipReloadDasn"
									toggle={toggleReloadDasn}
								>
									Acionar o robô para buscar dados do DASN SIMEI
								</Tooltip>

								<Button
									onClick={() =>
										history.push(`/companies/${companyId}/obligations/new`)
									}
									className="mr-2"
									outline
								>
									<MdAdd />
								</Button>
							</div>
						}
						onFetchData={handleFetchData}
						defaultSorted={[
							{
								id: "name",
								desc: true
							}
						]}
						onRowClicked={row =>
							history.push(`/companies/${companyId}/obligations/${row._id}`)
						}
						columns={[
							{
								Header: "Data criação",
								id: "createdAt",
								accessor: c =>
									c.createdAt
										? dayjs(c.createdAt).format(ls.dateFormatWithoutSeconds)
										: "--",
								width: 150
							},
							{
								Header: "Nome",
								id: "name",
								accessor: "name"
							},
							{
								Header: "Vencimento",
								id: "due",
								accessor: c =>
									c.due ? dayjs(c.due).format(ls.dateFormatShort) : "--",
								width: 140
							},
							{
								Header: "Ciclo",
								id: "period",
								show: true,
								accessor: c => ls[ObligationPeriods[c.period]],
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(ObligationPeriods)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ls[ObligationPeriods[c]]}
													</option>
												))}
										</select>
									);
								},
								width: 140
							},
							{
								Header: "Intervalo",
								id: "interval",
								accessor: "interval",
								width: 120
							},
							{
								Header: "Status",
								id: "status",
								show: true,
								accessor: c => {
									return (
										<Badge
											style={{
												fontSize: 12,
												backgroundColor: obligationColors[c.status]
											}}
										>
											{ObligationStatusDASN[c.status]}
										</Badge>
									);
								},
								Filter: ({ filter, onChange }) => {
									return (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter && filter.value ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(ObligationStatusDASN)
												.filter(c => !isNaN(+c))
												.map(c => (
													<option key={c} value={c}>
														{ObligationStatusDASN[c]}
													</option>
												))}
										</select>
									);
								},
								width: 140
							}
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
}

export default ObligationsInCompany;
