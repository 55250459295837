import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledTooltip
} from "reactstrap";
import ReportTable from "components/Report";
import _ from "lodash";
import * as api from "../../../../../services/api/axios";
import ExportExcel from "helpers/exportExcel";
import { FaRegFileExcel } from "react-icons/fa";
import ExportExcelForm from "containers/Company/components/ExportExcelForm";
import { toast } from "react-toastify";
import { stringToMask } from "helpers/string";
import dayjs from "dayjs";
import ls from "Localization";
import { dasStatus } from "types/dasStatus";
import ExportExcelDasForm from "./ExportExcelDasForm";

let getCompanyProviderStatusLisDebounced = null;

const CompanyProviderStatusListView = ({ businessId }) => {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { token } = useSelector(s => s.login);
	const export_excel_das_form = useSelector(s => s.form.export_excel_das_form);

	const [items, setItems] = useState([]);
	const [count, setCount] = useState(0);
	const [pages, setPages] = useState(0);
	const [loading, setLoading] = useState(false);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);

	const [modalExportExcelDAS, setModalExportExcelDAS] = useState(false);
	const toggleModalExportExcelDAS = () =>
		setModalExportExcelDAS(!modalExportExcelDAS);

	const [progress, setProgress] = useState(0);

	const handleFetchData = useCallback(
		tableState => {
			if (!tableState) return;
			let { page, pageSize, sorted } = tableState;

			setLoading(true);

			if (getCompanyProviderStatusLisDebounced) {
				getCompanyProviderStatusLisDebounced.cancel();
			}

			if (!sorted) sorted = [];

			getCompanyProviderStatusLisDebounced = _.debounce(
				() =>
					dispatch(async () => {
						const { data } = await api.sendGet(
							"api",
							`/companyProvider/status-list?filters[businessId]=${businessId}&skip=${page}&take=${pageSize}`,
							token,
							"bearer",
							null
						);

						setItems(data.model);
						setLoading(false);
						setCount(data.params.count);
						setPages(Math.ceil(data.params.count / pageSize));
					}),
				500
			);
			getCompanyProviderStatusLisDebounced();
		},
		["companyId"]
	);

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Nome da empresa": item.provider?.businessName || "",
				Nome: item.providerUser?.name || "",
				Telefone: item.providerUser?.phoneNumber || "",
				CNPJ: item.provider?.cnpj
					? stringToMask(item.provider?.cnpj, "cnpj")
					: "",
				"Status CNPJ": item.provider?.situation || "",
				Porte: item.provider?.businessType || "",
				Contrato: item.contracts || "",
				"NFS-e": item.nfses || "",
				DAS: item.das || "",
				Status: item.status || "",
				"Tem Dívida Ativa?": item.provider?.hasActiveDebit ? "Sim" : "Nao"
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = type => {
		dispatch(async () => {
			const dataTmp = [];
			const takeTmp = 250;

			for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
				const { data } = await api.sendGet(
					"api",
					`/companyProvider/status-list?filters[businessId]=${businessId}&skip=${skipTmp}&take=${takeTmp}`,
					token,
					"bearer",
					null
				);

				if (data.model) {
					dataTmp.push(...data.model);
					setProgress(Math.round((skipTmp / count) * 100));
				} else {
					console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
					break;
				}
			}

			ExportExcel(customData(dataTmp), "Lista_de_Prestadores", type);
			toggleModalExportExcel();
		});
	};

	const onSubmitExportExcel = data => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	const customDataDAS = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				Nome: item.provider?.businessName || item.providerUser?.name || "",
				CNPJ: item.provider?.cnpj
					? stringToMask(item.provider?.cnpj, "cnpj")
					: "",
				Período: item.dases?.period || "",
				"Data de vencimento": item.dases?.dueDate
					? dayjs(item.dases?.dueDate).format("YYYY-MM-DD")
					: "",
				"Pagar até o vencimento": item.dases?.receptionDate
					? dayjs(item.dases?.receptionDate).format("YYYY-MM-DD")
					: "",
				Status:
					item.dases?.status || item.dases?.status == 0
						? ls[dasStatus[item.dases?.status]]
						: "",
				"Código de Barras": item.dases?.barCode || "",
				Link: item.dases?.link || ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExportDAS = (type, data) => {
		dispatch(async () => {
			const dataTmp = [];
			const takeTmp = 500;

			let receptionDateStr = "";

			receptionDateStr = data.receptionDateInitDate
				? receptionDateStr +
				  `&receptionDateInitDate=${dayjs(data.receptionDateInitDate).format(
						"MM/YYYY"
				  )}`
				: "";

			receptionDateStr = data.receptionDateEndDate
				? receptionDateStr +
				  `&receptionDateEndDate=${dayjs(data.receptionDateEndDate).format(
						"MM/YYYY"
				  )}`
				: "";

			let dueDateStr = "";

			dueDateStr = data.dueDateInitDate
				? dueDateStr +
				  `&dueDateInitDate=${dayjs(data.dueDateInitDate).format("MM/YYYY")}`
				: "";

			dueDateStr = data.dueDateEndDate
				? dueDateStr +
				  `&dueDateEndDate=${dayjs(data.dueDateEndDate).format("MM/YYYY")}`
				: "";

			for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
				const { data } = await api.sendGet(
					"api",
					`/companyProvider/das-status-list?businessId=${businessId}&skip=${skipTmp}&take=${takeTmp}${receptionDateStr}${dueDateStr}`,
					token,
					"bearer",
					null
				);

				dataTmp.push(...data.model);
			}

			ExportExcel(customDataDAS(dataTmp), "Lista_de_Dases", type);
		});
	};

	const onSubmitExportExcelDAS = data => {
		toggleModalExportExcelDAS();

		if (count > 500) {
			toast.warning(
				<>
					Por favor, aguarde
					<br />
					Essa exportação pode levar alguns minutos...
				</>
			);
		}

		if (data.exportExcel) {
			handleExportDAS("xlsx", data);
		}

		if (data.exportCsv) {
			handleExportDAS("csv", data);
		}
	};

	return (
		<Container>
			<Card>
				<CardBody>
					<ReportTable
						tableRef={table}
						title={"Relatório de Status dos Prestadores"}
						data={items}
						pages={pages}
						loading={loading}
						count={count}
						sortable={false}
						onFetchData={handleFetchData}
						noExport={true}
						headerRightComponent={
							<div>
								<Button
									className={"mr-2 align-items-center"}
									outline
									onClick={toggleModalExportExcelDAS}
									id="export-das-list"
								>
									Exportar Lista de DAS
								</Button>
								<UncontrolledTooltip target="export-das-list">
									Exportar lista de DAS em aberto dos Prestadores
								</UncontrolledTooltip>

								<Button
									className={"mr-2"}
									outline
									onClick={toggleModalExportExcel}
									id="tooltipExcel"
								>
									<FaRegFileExcel style={{ margin: 0 }} />
								</Button>
								<UncontrolledTooltip target="tooltipExcel">
									Exportar os dados da tabela
								</UncontrolledTooltip>
							</div>
						}
						onRowClicked={row => {
							let uri = `/companies/${row?.provider?._id}`;
							if (!row?.provider) {
								uri = `/client/${row?.providerUser?._id}`;
							}
							let win = window.open(uri, "_blank");
							win.focus();
						}}
						columns={[
							{
								Header: "Nome da empresa",
								id: "provider.businessName",
								filterable: false,
								accessor: c => c.provider?.businessName || ""
							},
							{
								Header: "Nome",
								id: "providerUser.name",
								filterable: false,
								accessor: c => c.providerUser?.name || ""
							},
							{
								Header: "CNPJ",
								id: "provider.situation",
								filterable: false,
								accessor: c => `${c.provider?.situation || ""}`,
								width: 85
							},
							{
								Header: "Porte",
								id: "provider.businessType",
								filterable: false,
								accessor: c => ` ${c.provider?.businessType || ""}`,
								width: 85
							},
							{
								Header: "Contrato",
								id: "contracts",
								filterable: false,
								accessor: c => c.contracts || "",
								width: 150
							},
							{
								Header: "NFS-e",
								id: "nfses",
								filterable: false,
								accessor: c => c.nfses || "",
								width: 150
							},
							{
								Header: "DAS",
								id: "das",
								filterable: false,
								accessor: c => c.das || "",
								width: 200
							},
							{
								Header: "Status",
								id: "status",
								filterable: false,
								accessor: c => c.status || "",
								width: 85
							}
						]}
					/>
				</CardBody>
			</Card>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>

			<Modal isOpen={modalExportExcelDAS} toggle={toggleModalExportExcelDAS}>
				<ModalHeader
					toggle={toggleModalExportExcelDAS}
					className={"card__title bold-text"}
				>
					Exportar Relatório de DAS em aberto dos prestadores
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelDasForm
						onSubmit={onSubmitExportExcelDAS}
						isInvalid={
							export_excel_das_form && !!export_excel_das_form.syncErrors
						}
					/>
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default CompanyProviderStatusListView;
