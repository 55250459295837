// import dayjs from "dayjs";
// import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import { nfseStatus } from "types/nfseStatus";
import React, { useMemo } from "react";
import { Badge, Card, Col, Container, Input, Row } from "reactstrap";
import { toStringCurrency } from "helpers/string";
import { FaCloudUploadAlt } from "react-icons/fa";

import nfseServiceTypes from "../../../../assets/nfseServicetype.json";

const DetailNFSe = ({ nfse, onChange }) => {
	return (
		<div>
			<Card>
				<Container>
					<Row>
						<Col>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Informações de Acesso</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Login Prefeitura: </strong>
										<br />
										<span>{nfse?.mei?.nfEmissionLogin || "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Password Prefeitura: </strong>
										<br />
										<span>{nfse?.mei?.nfEmissionPassword || "--"}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Url Prefeitura: </strong>
										<br />
										{nfse?.mei?.nfEmissionUrl || "--"}
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Informações</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Status: </strong>
										<br />
										<span>{ls[nfseStatus[nfse?.status]]}</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>File: </strong>
										<br />
										{nfse?.file ? (
											<Badge
												color={"primary"}
												href={nfse.file}
												target={"_blank"}
											>
												Clique Aqui
											</Badge>
										) : (
											"--"
										)}
									</p>
								</Col>
								{nfse?.statusReason && (
									<Col className="mt-3" xs={12}>
										<p>
											<strong>Motivo do Status: </strong>
											<br />
											<span>{nfse?.statusReason}</span>
										</p>
									</Col>
								)}
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Emissor (Prestador de Serviço)</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Nome da Empresa: </strong>
										<br />
										<span>{nfse.mei ? nfse?.mei.businessName : "--"}</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>CNPJ: </strong>
										<br />
										<span>
											<span>{nfse?.mei?.cnpj ? nfse?.mei?.cnpj : "--"}</span>
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>CNAE: </strong>
										<br />
										<span>
											<span>{nfse?.mei?.occupation || "--"}</span>
										</span>
									</p>
								</Col>
							</Row>

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={12}>
									<p>
										<strong>Atividade: </strong>
										<br />
										<span>
											<span>{nfse?.mei?.activity || "--"}</span>
										</span>
									</p>
								</Col>
							</Row>

							{nfse?.mei?.secondaryActivities &&
							nfse?.mei?.secondaryActivities.length > 0 ? (
								<Row>
									<Col className="mt-3" xs={12} sm={12} md={6} xl={12}>
										<strong style={{ color: "#646677" }}>
											Atividades Secundárias:
										</strong>
										<ul>
											{nfse.mei.secondaryActivities.map(activity => (
												<li key={activity}>
													<span style={{ color: "#646677" }}>{activity}</span>
												</li>
											))}
										</ul>
									</Col>
								</Row>
							) : (
								""
							)}

							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Cliente (Tomador)</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Nome do Cliente: </strong>
										<br />
										<span>
											<span>
												{nfse?.clientMei?.businessName ||
													nfse?.client?.mei?.businessName ||
													nfse.client?.name}
											</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<p>
										<strong>CNPJ Cliente: </strong>
										<br />
										<span>
											<span>
												{nfse?.clientMei?.cnpj ||
													nfse?.client?.mei?.cnpj ||
													nfse?.client?.document}
											</span>
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={3}>
									<h4>
										<strong>Contato Financeiro Cliente</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Email do Cliente: </strong>
										<br />
										<span>
											<span>
												{nfse?.contactCompany?.email ||
													nfse?.client?.mei?.email ||
													nfse?.client?.email}
											</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Telefone do Cliente: </strong>
										<br />
										<span>
											<span>
												{nfse?.contactCompany?.phone || nfse?.client?.phone}
											</span>
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={12}>
									<p>
										<strong>ENDEREÇO: </strong>
										<br />
										<span>
											{useMemo(() => {
												const address =
													nfse.contactCompany?.address ||
													nfse.client?.mei?.address ||
													nfse.client?.address;

												if (!address) return "";

												const {
													street,
													number,
													complement,
													neighborhood,
													city,
													state,
													zipCode
												} = address;

												return `${street || ""}, ${number ||
													"sn"} - ${complement || ""}${
													complement ? " - " : ""
												}${neighborhood || ""} - ${city || ""} - ${state ||
													""} - ${zipCode || ""}`;
											}, [nfse])}
										</span>
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<h4>
										<strong>Descrição NFS-e</strong>
									</h4>
								</Col>
							</Row>
							<Row>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Tipo do Serviço </strong>
										<br />
										<span>
											<span>
												{(nfse?.serviceType &&
													nfseServiceTypes
														.find(c => nfse.serviceType.startsWith(c.code))
														?.items.find(c => c.code === nfse.serviceType)
														?.value) ||
													"Não informado"}
											</span>
										</span>
									</p>
								</Col>

								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Descrição </strong>
										<br />
										<span>
											<span>{nfse?.service ? nfse?.service : ""}</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Observação </strong>
										<br />
										<span>
											<span>
												{nfse?.observations ? nfse?.observations : ""}
											</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Valor </strong>
										<br />
										<span>
											<span>
												{nfse?.totalValue
													? toStringCurrency(+nfse?.totalValue / 100.0)
													: "--"}
											</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Data da Solicitação </strong>
										<br />
										<span>
											<span>{nfse?.createdAt ? nfse?.createdAt : "--"}</span>
										</span>
									</p>
								</Col>
								<Col className="mt-3" xs={12} sm={12} md={6} xl={4}>
									<p>
										<strong>Lançamento Financeiro? </strong>
										<br />
										<span>
											<span>{nfse?.generateFinancial ? "Sim" : "--"}</span>
										</span>
									</p>
								</Col>
							</Row>
						</Col>
						{nfse?.file ? (
							<Col className="mt-3" xs={12} sm={12} md={6} xl={6}>
								<object data={nfse?.file} width="100%" height="100%"></object>
							</Col>
						) : (
							<Col
								xs={12}
								sm={12}
								md={6}
								xl={6}
								className="d-flex justify-content-center align-items-center"
							>
								<Row>
									<Col>
										<FaCloudUploadAlt
											size={150}
											style={{
												color: "#504c9a"
											}}
										/>
										<Input
											id="exampleFile"
											name="file"
											type="file"
											onChange={onChange}
										/>
									</Col>
								</Row>
								<Row></Row>
							</Col>
						)}
					</Row>
				</Container>
			</Card>
		</div>
	);
};

export default DetailNFSe;
