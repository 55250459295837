import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FiDownload } from "react-icons/fi";
import dayjs from "dayjs";

import * as api from "services/api";

import BasicModal from "components/StyledModal";
import LoadingComponent from "components/LoadingComponent";
import { SelectInput } from "components/SelectInput";
import { StyledTable } from "components/StyledTable";
import { TextInput } from "components/TextInput";

import ls from "Localization";

import { stringToMask } from "helpers/string";

import { businessLicenseStatus } from "types/BusinessLicenseStatus";
import { contractStatus } from "types/contractStatus";
import { dasStatus } from "types/dasStatus";
import ExportExcel from "helpers/exportExcel";
import { nfseStatus } from "types/nfseStatus";
import { ObligationStatusDASN } from "types/obligationStatus";
import phoneNumber from "helpers/phoneNumber";

import {
	AdditionalFiltersContainer,
	AnimatedAdditionalFilterContainer,
	ButtonAlignerContainer,
	ButtonsContainer,
	ChangeFiltersExhibitionButton,
	ExportExcelButton,
	FilterHeaderText,
	FilterSection,
	FilterTitle,
	InputsContainer,
	MainFiltersContainer,
	PageTitle,
	PageWrapper,
	RightSideButtonsContainer,
	StatusBadge,
	TableCellButton
} from "./styles";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Tooltip
} from "@material-ui/core";
import { toast } from "react-toastify";

export default function NewHirersDashboard() {
	const [isAdditionalFiltersVisible, setIsAdditionalFiltersVisible] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(10);
	const [count, setCount] = useState(0);

	const [filterUrl, setFilterUrl] = useState("");

	const [progress, setProgress] = useState(0);
	const [exportType, setExportType] = useState("xlsx");
	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () =>
		setModalExportExcel(prevState => !prevState);

	const formRef = useRef(null);

	const { token } = useSelector(s => s.login);

	const copyToClipboard = async text => {
		try {
			await navigator.clipboard.writeText(text);
			toast.success("Texto copiado para a área de transferência: " + text);
		} catch (err) {
			toast.warning("Falha ao copiar o texto");
			console.log("Falha ao copiar o texto", err);
		}
	};

	const tableColumns = [
		{
			field: "providerUser",
			headerName: <span>Nome</span>,
			align: "left",
			alignContent: "left",
			sticky: true,
			width: 230,
			content: value => (
				<Tooltip title="Clique para abrir o perfil do Usuário" placement="top">
					<TableCellButton onClick={() => window.open(`/client/${value._id}`)}>
						<span>{value?.name}</span>
					</TableCellButton>
				</Tooltip>
			)
		},
		{
			field: "provider.cnpj",
			headerName: <span>CNPJ</span>,
			align: "left",
			alignContent: "left",
			width: 155,
			content: value =>
				value ? (
					<Tooltip
						title="Clique para copiar o cnpj para área de transferência"
						placement="top"
					>
						<TableCellButton onClick={() => copyToClipboard(value)}>
							<span>{stringToMask(value, "cnpj")}</span>
						</TableCellButton>
					</Tooltip>
				) : (
					<span>Pendente</span>
				)
		},
		{
			field: "provider",
			headerName: <span>Empresa</span>,
			align: "left",
			alignContent: "left",
			width: 200,
			content: value =>
				value ? (
					<Tooltip
						title="Clique para abrir o perfil do Empresa Prestadora"
						placement="top"
					>
						<TableCellButton
							onClick={() => window.open(`/companies/${value._id}`)}
						>
							<span>{value.businessName}</span>
						</TableCellButton>
					</Tooltip>
				) : (
					<span>Pendente</span>
				)
		},
		{
			field: "business",
			headerName: <span>Contratante</span>,
			align: "left",
			alignContent: "left",
			width: 200,
			content: value =>
				value ? (
					<Tooltip
						title="Clique para abrir o perfil do Empresa Contratante"
						placement="top"
					>
						<TableCellButton
							onClick={() => window.open(`/companies/${value._id}`)}
						>
							<span>{value.businessName}</span>
						</TableCellButton>
					</Tooltip>
				) : (
					<span>Pendente</span>
				)
		},
		{
			field: "provider.situation",
			headerName: <span>CNPJ</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === "ATIVO"
							? "#3ca647"
							: value === "INAPTA" || value === "BAIXADA"
							? "#ca1e11"
							: value === "PENDENTE"
							? "#6d757d"
							: value === "EM ANDAMENTO"
							? "#bcb91b"
							: "#6d757d"
					}
				>
					<span>{value || "PENDENTE"}</span>
				</StatusBadge>
			)
		},
		{
			field: "provider.businessLicenseStatus",
			headerName: <span>Alvará</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0 || value === 1
							? "#6d757d"
							: value === 2
							? "#bcb91b"
							: value === 3
							? "#3ca647"
							: value === 4
							? "#ca1e11"
							: value === 5
							? "#3ba0b7"
							: "#6d757d"
					}
				>
					<span>
						{value
							? ls[businessLicenseStatus[value]]?.toUpperCase()
							: "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "provider.nfeStatus",
			headerName: <span>NFS-e</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0 || value === 1
							? "#bcb91b"
							: value === 2
							? "#3ca647"
							: value === 3
							? "#3ba0b7"
							: value === 4 || value === 9
							? "#ca1e11"
							: "#6d757d"
					}
				>
					<span>
						{value ? ls[nfseStatus[value]]?.toUpperCase() : "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "contractStatus",
			headerName: <span>Contratos</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0 || value === 5 || value === 6 || value === 9
							? "#bcb91b"
							: value === 1
							? "#3ca647"
							: value === 2 || value === 3 || value === 4 || value === 7
							? "#ca1e11"
							: value === 8
							? "#3ba0b7"
							: "#6d757d"
					}
				>
					<span>
						{value ? ls[contractStatus[value]]?.toUpperCase() : "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "provider.hasActiveDebit",
			headerName: <span>Dívida Ativa</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge variant={value ? "#ca1e11" : "#3ca647"}>
					<span>{value ? "SIM" : "NÃO"}</span>
				</StatusBadge>
			)
		},
		{
			field: "dasStatus",
			headerName: <span>Impostos</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0
							? "#bcb91b"
							: value === 1
							? "#3ca647"
							: value === 2
							? "#ca1e11"
							: value === 3
							? "#6d757d"
							: "#6d757d"
					}
				>
					<span>
						{value ? ls[dasStatus[value]]?.toUpperCase() : "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "obligationStatus",
			headerName: <span>Obrigações</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0
							? "#bcb91b"
							: value === 1
							? "#3ca647"
							: value === 2
							? "#ca1e11"
							: value === 3
							? "#6d757d"
							: "#6d757d"
					}
				>
					<span>
						{value || value === 0
							? ObligationStatusDASN[value]?.toUpperCase()
							: "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "insurance",
			headerName: <span>Seguros</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0
							? "#bcb91b"
							: value === 1
							? "#3ca647"
							: value === 2
							? "#ca1e11"
							: "#6d757d"
					}
				>
					<span>
						{value === 0
							? "PENDENTE"
							: value === 1
							? "HABILITADO"
							: value === 2
							? "DESABILITADO"
							: "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "status",
			headerName: <span>Status</span>,
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => (
				<StatusBadge
					variant={
						value === 0
							? "#6d757d"
							: value === 1
							? "#bcb91b"
							: value === 2
							? "#3ca647"
							: value === 3
							? "#ca1e11"
							: "#6d757d"
					}
				>
					<span>
						{value === 0
							? "PENDENTE"
							: value === 1
							? "ONBOARD"
							: value === 2
							? "ATIVO"
							: value === 3
							? "INATIVO"
							: "PENDENTE"}
					</span>
				</StatusBadge>
			)
		},
		{
			field: "provider.lastUpdatedDas",
			headerName: (
				<span>
					Atualização
					<br />
					DAS
				</span>
			),
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => {
				if (!value) {
					return (
						<StatusBadge variant="#6d757d">
							<span>PENDENTE</span>
						</StatusBadge>
					);
				}
				const differenceInDays = getDifferenceInDaysFromToday(value);

				if (!differenceInDays && differenceInDays !== 0) {
					return;
				}

				return (
					<StatusBadge
						variant={
							differenceInDays === 0 || differenceInDays === 1
								? "#3ca647"
								: differenceInDays >= 2 && differenceInDays <= 7
								? "#bcb91b"
								: "#ca1e11"
						}
					>
						<span>
							{differenceInDays === 0
								? "HOJE"
								: differenceInDays === 1
								? "ONTEM"
								: differenceInDays >= 2 && differenceInDays <= 7
								? `${differenceInDays} DIAS`
								: "+7 DIAS"}
						</span>
					</StatusBadge>
				);
			}
		},
		{
			field: "provider.lastUpdatedDasn",
			headerName: (
				<span>
					Atualização
					<br />
					DASN
				</span>
			),
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => {
				if (!value) {
					return (
						<StatusBadge variant="#6d757d">
							<span>PENDENTE</span>
						</StatusBadge>
					);
				}

				const differenceInDays = getDifferenceInDaysFromToday(value);

				if (!differenceInDays && differenceInDays !== 0) {
					return;
				}

				return (
					<StatusBadge
						variant={
							differenceInDays === 0 || differenceInDays === 1
								? "#3ca647"
								: differenceInDays >= 2 && differenceInDays <= 7
								? "#bcb91b"
								: "#ca1e11"
						}
					>
						<span>
							{differenceInDays === 0
								? "HOJE"
								: differenceInDays === 1
								? "ONTEM"
								: differenceInDays >= 2 && differenceInDays <= 7
								? `${differenceInDays} DIAS`
								: "+7 DIAS"}
						</span>
					</StatusBadge>
				);
			}
		},
		{
			field: "provider.updatedAtReceitaWS",
			headerName: (
				<span>
					Atualização
					<br />
					CNPJ
				</span>
			),
			align: "center",
			alignContent: "center",
			width: 85,
			content: value => {
				if (!value) {
					return (
						<StatusBadge variant="#6d757d">
							<span>PENDENTE</span>
						</StatusBadge>
					);
				}
				const differenceInDays = getDifferenceInDaysFromToday(value);

				if (!differenceInDays && differenceInDays !== 0) {
					return;
				}

				return (
					<StatusBadge
						variant={
							differenceInDays <= 7
								? "#3ca647"
								: differenceInDays > 7 && differenceInDays < 14
								? "#bcb91b"
								: "#ca1e11"
						}
					>
						<span>
							{differenceInDays === 0
								? "HOJE"
								: differenceInDays === 1
								? "ONTEM"
								: differenceInDays >= 2 && differenceInDays < 14
								? `${differenceInDays} DIAS`
								: "+14 DIAS"}
						</span>
					</StatusBadge>
				);
			}
		}
	];

	function handleOpenAdditionalFilters() {
		setIsAdditionalFiltersVisible(prevState => !prevState);
	}

	function handleChangeSkip(skip) {
		setSkip(skip);
	}

	function handleChangeTake({ target }) {
		setSkip(0);
		setTake(target.value);
	}

	function getDifferenceInDaysFromToday(date) {
		if (!date) {
			return;
		}

		const dayjsDate1 = dayjs(date);
		const dayjsDate2 = dayjs(new Date());

		const diffInDays = dayjsDate2.diff(dayjsDate1, "day");

		return Math.abs(diffInDays);
	}

	async function fetchProviders() {
		try {
			setIsLoading(true);

			const response = await api.sendGet(
				`/companyProvider/contracted-company-report?skip=${skip *
					take}&take=${take}&sort=createdAt&desc=true&filters[provider.isHired]=false${filterUrl}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setTableData(result.model);
				setCount(result.params.count);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleClearFields() {
		formRef.current.reset();
	}

	function handleFiltersSubmit(event) {
		event.preventDefault();

		const formData = new FormData(formRef.current);

		let filterUrl = "";
		let iteratorCount = 0;

		for (let [key, value] of formData.entries()) {
			if (iteratorCount >= 4 && !isAdditionalFiltersVisible) {
				break;
			}

			if (value) {
				filterUrl += `&filters[${key}]=${value}`;
			}

			iteratorCount++;
		}

		setFilterUrl(filterUrl);
	}

	const handleExport = async type => {
		const dataList = [];
		const customTake = 500;

		for (let i = 0; i < count; i += customTake) {
			const response = await api.sendGet(
				`/v2/relatorio/data-insurance?skip=${i}&take=${customTake}&sort=createdAt&desc=true$&filters=null&CompanyProvider_deleted=false`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.length) {
				dataList.push(...result);
				setProgress(Math.round((i / count) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", i, customTake);
				break;
			}
		}

		console.log({ dataList });

		ExportExcel(customData(dataList), "Lista_de_Prestadores", type);

		toggleModalExportExcel();
	};

	const customData = data => {
		let rows = [];

		for (let item of data) {
			const telefone = item["Telefone"];

			rows.push({
				...item,
				Telefone: telefone ? phoneNumber.format(telefone) : ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const onSubmitExportExcel = () => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (exportType === "xlsx") {
			handleExport("xlsx");
		}

		if (exportType === "csv") {
			handleExport("csv");
		}
	};

	useEffect(() => {
		fetchProviders();
	}, [skip, take, filterUrl]);

	return (
		<PageWrapper>
			<PageTitle>
				Relatório de prestadores <span>- Total {count}</span>
			</PageTitle>

			<FilterSection>
				<FilterTitle>Filtro</FilterTitle>

				<InputsContainer>
					<FilterHeaderText>Informações</FilterHeaderText>

					<form ref={formRef} onSubmit={handleFiltersSubmit}>
						<MainFiltersContainer>
							<TextInput
								label="Nome"
								placeholder="Nome do prestador"
								name="providerUser.name"
							/>
							<TextInput
								label="CNPJ"
								placeholder="Número do CNPJ"
								name="provider.cnpj"
							/>
							<TextInput
								label="Empresa"
								placeholder="Empresa do prestador"
								name="provider.businessName"
							/>
							<TextInput
								label="Contratante"
								placeholder="Nome do contratante"
								name="business.businessName"
							/>
						</MainFiltersContainer>

						<AnimatedAdditionalFilterContainer
							isOpen={isAdditionalFiltersVisible}
						>
							<FilterHeaderText style={{ marginTop: "16px" }}>
								Filtros adicionais
							</FilterHeaderText>

							<AdditionalFiltersContainer>
								<SelectInput
									label="Status da Dívida Ativa"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Não", value: 0 },
										{ label: "Sim", value: 1 }
									]}
									name="provider.hasActiveDebit"
								/>
								<SelectInput
									label="Status CNPJ"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Ativo", value: "ATIVO" },
										{ label: "Inapta", value: "INAPTA" },
										{ label: "Baixada", value: "BAIXADA" },
										{ label: "Suspenso", value: "SUSPENSO" },
										{ label: "Em andamento", value: "Em Andamento" },
										{ label: "Pendente", value: "Pendente" }
									]}
									name="provider.situation"
								/>
								<SelectInput
									label="Status Alvará"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Pendente", value: 0 },
										{ label: "Verificando", value: 1 },
										{ label: "Nenhum", value: 2 },
										{ label: "Ativo", value: 3 },
										{ label: "Vencido", value: 4 },
										{ label: "Não optante", value: 5 }
									]}
									name="provider.businessLicenseStatus"
								/>
								<SelectInput
									label="Seguro"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Pendente", value: 0 },
										{ label: "Habilitado", value: 1 },
										{ label: "Desabilitado", value: 2 }
									]}
									name="insurance"
								/>
								<SelectInput
									label="Status dos Impostos"
									options={[
										{ label: "Todos", value: "" },
										{ label: "A Vencer", value: 0 },
										{ label: "Liquidado", value: 1 },
										{ label: "Devedor", value: 2 },
										{ label: "Pendente", value: 3 }
									]}
									name="dasStatus"
								/>
								<SelectInput
									label="Status das Obrigações"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Declarar", value: 0 },
										{ label: "Retificar", value: 1 },
										{ label: "Atrasado", value: 2 }
									]}
									name="obligationStatus"
								/>
								<SelectInput
									label="Status NFS-e"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Pendente", value: 0 },
										{ label: "Habilitando", value: 1 },
										{ label: "Ativo", value: 2 },
										{ label: "Não optante", value: 3 }
									]}
									name="provider.nfeStatus"
								/>
								<SelectInput
									label="Status do Contrato"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Pendente", value: 0 },
										{ label: "Assinado", value: 1 },
										{ label: "Vencido", value: 2 },
										{ label: "Cancelado", value: 3 },
										{ label: "Rescindido", value: 4 },
										{ label: "Contratante assinado", value: 5 },
										{ label: "Prestador assinado", value: 6 },
										{ label: "Excluído", value: 7 },
										{ label: "Não optante", value: 8 },
										{ label: "Aguardando assinatura", value: 9 }
									]}
									name="contractStatus"
								/>
								<SelectInput
									label="Status"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Pendente", value: 0 },
										{ label: "Onboard", value: 1 },
										{ label: "Ativo", value: 2 },
										{ label: "Inativo", value: 3 }
									]}
									name="status"
								/>
								<SelectInput
									label="Atualização DAS"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Recente", value: "recent" },
										{
											label: "Última semana",
											value: "lastWeek"
										},
										{ label: "Mais antigo", value: "older" }
									]}
									name="provider.lastUpdatedDas"
								/>
								<SelectInput
									label="Atualização DASN"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Recente", value: "recent" },
										{
											label: "Última semana",
											value: "lastWeek"
										},
										{ label: "Mais antigo", value: "older" }
									]}
									name="provider.lastUpdatedDasn"
								/>
								<SelectInput
									label="Atualização CNPJ"
									options={[
										{ label: "Todos", value: "" },
										{ label: "Recente", value: "recent" },
										{
											label: "Última semana",
											value: "lastWeek"
										},
										{ label: "Mais antigo", value: "older" }
									]}
									name="provider.updatedAtReceitaWS"
								/>
							</AdditionalFiltersContainer>
						</AnimatedAdditionalFilterContainer>

						<ButtonsContainer>
							<ChangeFiltersExhibitionButton
								onClick={handleOpenAdditionalFilters}
								type="button"
							>
								{isAdditionalFiltersVisible
									? "Recolher filtros adicionais"
									: "Expandir filtros adicionais"}
							</ChangeFiltersExhibitionButton>

							<RightSideButtonsContainer>
								<button type="button" onClick={handleClearFields}>
									Limpar
								</button>
								<button type="submit">Aplicar</button>
							</RightSideButtonsContainer>
						</ButtonsContainer>
					</form>
				</InputsContainer>
			</FilterSection>

			<ButtonAlignerContainer>
				<ExportExcelButton onClick={toggleModalExportExcel}>
					<FiDownload size={24} color="#fff" />
				</ExportExcelButton>
			</ButtonAlignerContainer>

			{isLoading ? (
				<LoadingComponent />
			) : (
				<StyledTable
					data={tableData}
					columns={tableColumns}
					handleChangeSkip={handleChangeSkip}
					handleChangeTake={handleChangeTake}
					skip={skip}
					take={take}
					count={count}
					showHeader
					rowClick={false}
				/>
			)}

			<BasicModal open={modalExportExcel} handleClose={toggleModalExportExcel}>
				<Grid
					container
					direction="column"
					spacing={2}
					style={{ marginBottom: "16px" }}
				>
					<Grid item style={{ borderBottom: "1px solid #ccc", width: "100%" }}>
						<span>Exportar Relatório</span>
					</Grid>
				</Grid>

				<Grid container justifyContent="space-between">
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									defaultChecked
									onChange={() => setExportType("xlsx")}
									color="primary"
								/>
							}
							label="Excel"
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={() => setExportType("csv")}
									color="primary"
								/>
							}
							label="Csv"
						/>
					</FormGroup>
				</Grid>

				<div className="progress" style={{ margin: "16px 0" }}>
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						aria-valuenow={progress}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: progress + "%" }}
					>
						{progress}%
					</div>
				</div>

				<Grid
					container
					spacing={1}
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<RightSideButtonsContainer>
						<button onClick={toggleModalExportExcel} type="button">
							Cancelar
						</button>

						<button onClick={() => onSubmitExportExcel()}>Exportar</button>
					</RightSideButtonsContainer>
				</Grid>
			</BasicModal>
		</PageWrapper>
	);
}
